import styled, { css } from 'styled-components';

import { TInputState, TInputStyleVariantsKey } from 'shared/design-system/theme/inputs/TInputs';
import { getInputStyleVariantValues } from 'shared/design-system/theme/inputs/utils';

export const InputIconWrapper = styled.div`
  position: relative;
`;

export const IconButton = styled.button`
  position: absolute;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  right: 12px;
  top: 12px;
`;

export type TInputStyledProps = {
  $styleVariant: TInputStyleVariantsKey;
  $isFullWidth: boolean;
  $inputState: TInputState;
};

export const Input = styled.input<TInputStyledProps>`
  ${({ $isFullWidth, $styleVariant, $inputState, theme }) => {
    const {
      background,
      backgroundActive,
      border,
      borderActive,
      borderRadius,

      placeholderFontColor,
      valueFontColor,
    } = getInputStyleVariantValues(theme, $styleVariant, $inputState);

    const focusStyles = `
      background: ${backgroundActive};
      border: ${borderActive};
      z-index: 1;
    `;
    return `
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-appearance: none;
    font-family: AvenirLTPro;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 8px 12px;
    border: ${border};  
    border-radius: ${borderRadius};
    color: ${valueFontColor};
    width: ${$isFullWidth ? '100%' : 'auto'};
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: ellipsis;
    background: ${background}; 
    outline: none;

    &::placeholder {
      color: ${placeholderFontColor};
    }
    &:-ms-input-placeholder {
      color: ${placeholderFontColor};
    }

    &:focus {
      ${focusStyles}
    }
`;
  }}
`;

type TTextInputContainerOuterProps = {
  $styleVariant: TInputStyleVariantsKey;
  $isFullWidth: boolean;
  $margin: string;
  $inputState: TInputState;
};

export const TextInputContainerOuter = styled.div<TTextInputContainerOuterProps>`
  ${({ $margin, $styleVariant, $isFullWidth, $inputState, theme }) => {
    const { backgroundHover, borderHover } = getInputStyleVariantValues(
      theme,
      $styleVariant,
      $inputState,
    );

    return `
      margin: ${$margin};
      display: flex;
      flex-direction: column;
      width: ${$isFullWidth ? '100%' : 'auto'};
      max-width: 700px;
      
      &:hover input:not(:focus) {
        background: ${backgroundHover};
        border: ${borderHover};
      }
  `;
  }}
`;

type InputLabelProps = {
  $styleVariant: TInputStyleVariantsKey;
  $inputState: TInputState;
};

export const InputLabel = styled.label<InputLabelProps>`
  ${({ $styleVariant, $inputState, theme }) => {
    const { labelFontColor } = getInputStyleVariantValues(theme, $styleVariant, $inputState);

    return css`
      font-family: Avenir;
      margin: 0 auto 4px 0;
      color: ${labelFontColor};
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    `;
  }}
`;

type InputHintProps = {
  $styleVariant: TInputStyleVariantsKey;
  $inputState: TInputState;
  $showHint: boolean;
};

export const InputHint = styled.div<InputHintProps>`
  ${({ $inputState, $styleVariant, $showHint, theme }) => {
    const { hintFontColor } = getInputStyleVariantValues(theme, $styleVariant, $inputState);

    return css`
      display: ${$showHint ? 'flex' : 'none'};
      color: ${hintFontColor};
      font-family: Avenir;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding-top: 4px;
      align-items: center;
      gap: 8px;
    `;
  }}
`;
